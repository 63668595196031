import { API } from '../../Services/axios';
import { Alert, Button, Col, Form, Input, Row, Typography } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { getConfirmPasswordRule, getPasswordRule } from '../../Utils/Rules/registration';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../Modals/ErrorModal';
import { useSearchParams } from 'react-router-dom';

export const NewPasswordPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { showErrorModal } = useErrorModal();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const [successToken, setSuccessToken] = useState(false);
  const [successPassword, setSuccessPassword] = useState(false);

  useEffect(() => {
    if (token) {
      API.post(`/api/v1/auth/verify-token?token=${token}`)
        .then((response) => {
          // eslint-disable-next-line no-console
          console.log(response);
          setSuccessToken(true);
        })
        .catch((error) => {
          showErrorModal({ error });
        })
        .finally(() => setLoading(false));
    }
  }, []);

  const onFinish = (values) => {
    API.post('/api/v1/auth/save-password', { newPassword: values.password, token: token })
      .then(() => {
        setSuccessPassword(true);
      })
      .catch((error) => {
        showErrorModal({ error });
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="login">
      <div className="login__card">
        <Col span={24}>
          <div className="login__card__header">
            <Typography.Title level={1} style={{ fontSize: '24px' }}>
              Смена пароля
            </Typography.Title>
          </div>
          {successPassword && (
            <Alert
              message={<Typography.Text className="contract__info">Пароль успешно обновлен</Typography.Text>}
              type="success"
              showIcon
              icon={<CheckCircleOutlined />}
            />
          )}
          {successToken && (
            <Form layout="vertical" colon={false} className="form" onFinish={onFinish}>
              <Row justify="space-between" gutter={[24, 24]}>
                <Col span={24}>
                  <Form.Item name="password" label="Новый пароль" validateFirst rules={getPasswordRule()}>
                    <Input.Password size="large" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="confirmPassword"
                    label="Повторите новый пароль"
                    validateFirst
                    rules={getConfirmPasswordRule()}
                    dependencies={['password']}
                  >
                    <Input.Password size="large" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item className="profile__change-pass-btn">
                    <Button type="primary" htmlType="submit" size="large" loading={loading}>
                      Изменить
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
        </Col>
      </div>
    </div>
  );
};
