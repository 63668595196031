import { API } from '../../Services/axios';
import { Button, Form, Modal, Select, Space, Upload, UploadProps } from 'antd';
import { ModalProps } from '../../Models/Modal';
import { clickDownloadLink } from '../../Utils/clickDownloadLink';
import { useAppSelector } from '../../Store/hooks';
import { useErrorModal } from '../Modals/ErrorModal';
import { useForm } from 'antd/lib/form/Form';
import ModalTitle from '../Modals/ModalTitle';
import React, { useMemo, useState } from 'react';
import cadesplugin from 'crypto-pro-cadesplugin';

const useDoCertsList = () =>
  useMemo(async () => {
    const certsApi = await cadesplugin();
    const certsList = await certsApi.getCertsList();

    return certsList.map(({ subjectInfo, thumbprint }) => ({
      value: thumbprint,
      label: subjectInfo,
    }));
  }, []);

const EdoSignModal = (props: ModalProps) => {
  const { open, handleCancel } = props;
  const { showErrorModal } = useErrorModal();
  const [listCert, setListCert] = useState([{ value: 'подпись', label: 'подпись' }]);
  const [hash, setHash] = useState('');
  const [form] = useForm();
  const profile = useAppSelector((state) => state.user);

  const uploadProps: UploadProps = {
    name: 'act',
    accept: '.pdf,.docx',
    maxCount: 1,
    customRequest: ({ file, onSuccess }) =>
      setTimeout(() => {
        onSuccess!('ok');
      }, 0),
  };

  const handleChangeFile = (e: any) => {
    return e?.fileList;
  };

  const getHash = async (values: any) => {
    const formData = new FormData();
    if (values.file && values.file.length > 0) {
      formData.append('file', values.file['0'].originFileObj);
    }
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    API.post(`/edo/api/v1/hash`, formData, headers)
      .then((response) => {
        setHash(response.data.hash);
      })
      .catch((error) => showErrorModal({ error }));
  };

  const getSign = async () => {
    const fileJK = form.getFieldValue('file');
    const thumbprint = form.getFieldValue('thumbprint');

    const certsApi = await cadesplugin();
    const sign = await certsApi.signBase64(thumbprint, hash);
    const reportData = {
      profile: {
        ...profile,
      },
      signature: sign,
    };
    const formData = new FormData();
    if (fileJK) {
      formData.append('file', fileJK[0].originFileObj);
    }

    formData.append('json', JSON.stringify(reportData));
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    API.post(`/edo/api/v1/jcp`, formData, headers)
      .then((resp) => {
        const blob = resp.data;
        clickDownloadLink(blob, `Подписанный договор.pdf`);
      })
      .catch((error) => showErrorModal({ error }));
  };

  useDoCertsList().then(setListCert);

  return (
    <Modal
      open={open}
      title={<ModalTitle level={3} title="Создание подписи" handleCancel={handleCancel} />}
      width={655}
      closable={false}
      footer={null}
      centered
      className="modal"
    >
      <Space direction="vertical" size={32} className="modal__full-width">
        <Form id="form" form={form} colon={false} layout="vertical" onFinish={getHash}>
          <Form.Item name="file" valuePropName="fileList" getValueFromEvent={handleChangeFile}>
            <Upload {...uploadProps}>
              <Button>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item label="Выберите сертификат" name="thumbprint">
            <Select options={listCert} />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Получить хеш
          </Button>
          <Button type="dashed" color="primary" htmlType="button" onClick={() => getSign()} disabled={!hash}>
            Подписать договор
          </Button>
        </Form>
      </Space>
    </Modal>
  );
};

export default EdoSignModal;
