/* eslint-disable no-undef */
import axios from 'axios';

export const API = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL,
});

API.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

API.interceptors.response.use(
  function (response) {
    let respUrl = response.request.responseURL;
    if (response.status === 304) {
      window.location.href = respUrl;
    }
    if (response.status === 400) throw new Error(response.details);
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if ((error.response.status === 401 || error.response.status === 403) && error.config && !error.config._isRetry) {
      originalRequest._isRetry = true;
      try {
        let refreshToken = localStorage.getItem('refresh_token');
        const { data } = await API.post('api/v1/auth/refresh-token', { refreshToken });
        localStorage.setItem('access_token', data.accessToken);
        localStorage.setItem('refresh_token', data.refreshToken);
        return API.request(originalRequest);
      } catch (e) {
        localStorage.removeItem('userInfo');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('access_token');
        API.get('/api/v1/logout').then(() => window.location.replace(`${process.env.REACT_APP_API_URL}signin`));
      }
      throw error.response.data.details;
    }
    return Promise.reject(error.response.data);
  },
);
