import { API } from '../../Services/axios';
import { Alert, Button, Checkbox, Col, Form, Input, Row, Space, Typography } from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { getEmailFieldRules, getPasswordRule } from '../../Utils/Rules/registration';
import { login } from '../../Utils/login';
import { useErrorModal } from '../Modals/ErrorModal';
import { useLocation, useNavigate } from 'react-router-dom';

interface ILoginPage {
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const LoginPage = (props: ILoginPage) => {
  const { setLoading } = props;
  const { showErrorModal } = useErrorModal();
  const navigate = useNavigate();
  const location = useLocation();
  const previousPath = location.state?.from ? location.state.from : '/';
  const [badCredentials, setBadCredentials] = useState(false);

  const onFinish = (values) => {
    setBadCredentials(false);
    API.post('/api/v1/auth/login', values)
      .then((response) => {
        localStorage.setItem('access_token', response.data.token);
        localStorage.setItem('refresh_token', response.data.refreshToken);
        login(navigate, previousPath).then(() => setLoading(false));
      })
      .catch((error) => {
        setBadCredentials(true);
        showErrorModal({ error });
      });
  };
  return (
    <div className="login">
      <div className="login__card">
        <Col span={24}>
          <div className="login__card__header">
            <Typography.Title level={1} style={{ fontSize: '24px' }}>
              Сервис Единого Входа VERUM
            </Typography.Title>
          </div>
          {badCredentials && (
            <Alert
              message={<Typography.Text className="contract__info">Неверный логин или пароль</Typography.Text>}
              type="error"
              showIcon
              icon={<ExclamationCircleFilled />}
            />
          )}
          <Form layout="vertical" className="form" onFinish={onFinish}>
            <Form.Item
              name="username"
              label="Электронная почта"
              validateFirst
              rules={getEmailFieldRules('Введите электронную почту')}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item name="password" label="Пароль" validateFirst rules={getPasswordRule()}>
              <Input.Password size="large" />
            </Form.Item>
            <div className="login__card__settings">
              <Space size={8} align="start" className="login__card__checkbox">
                <Checkbox>
                  <Typography.Text>Запомнить на сайте?</Typography.Text>
                </Checkbox>
              </Space>
              <Space className="login__card__link">
                <Typography.Link href="/resetPassword">Забыли пароль?</Typography.Link>
              </Space>
            </div>
            <Row justify="center" className="login__card__footer">
              <Space direction="vertical" align="center" size={16}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    className="login__card__button"
                    // loading={loading}
                  >
                    Войти
                  </Button>
                </Form.Item>
                <Space>
                  <Typography.Text>Новый пользователь?</Typography.Text>
                  <Space className="login__card__link">
                    <Typography.Link href="/registration">Регистрация</Typography.Link>
                  </Space>
                </Space>
              </Space>
            </Row>
          </Form>
        </Col>
      </div>
    </div>
  );
};

export default LoginPage;
